import { ApiSeasonData, ApiSeasonUser } from 'features/graftipp/types';
import { groupBy } from './useGroup';

const maxPointsPerGame = 3;

export function prepareSeasonUsers(seasonData: ApiSeasonData) {
    let { users, seasonsGamedays, seasonPlaces } = seasonData;
    const participants = users.length;

    let bets = users.map((user) => user.bets).flat();
    let gamedays = seasonsGamedays.map((sg) => sg.gameday);
    let betsByGameday = groupBy(bets, (bet) => bet.gameday.id);

    let pott = 0;

    const gamedaysMap = new Map(
        gamedays.map((gameday) => {
            const gd = betsByGameday[gameday.id] ?? [];
            const maxValue = Math.max.apply(
                Math,
                gd.map((x) => x.points),
            );
            const winners = gd.filter((x) => x.points === maxValue).length;
            const pointsTotal = gd.reduce((p, c) => p + c.points, 0);
            const missedTotal = gd.reduce((p, c) => p + c.missed, 0);
            const moneyTotal = 10 * (9 * maxPointsPerGame * participants + missedTotal - pointsTotal);
            const obj = { ...gameday, maxValue, winners, pointsTotal, missedTotal, moneyTotal };
            pott += moneyTotal;
            return [gameday.id, obj];
        }),
    );
    pott *= 0.0075;

    let winnings = seasonPlaces.map((place) => place.percentage);
    for (let i = winnings.length; i < participants; i++) {
        winnings.push(0);
    }

    let userBefore: ApiSeasonUser;
    let place = 1;

    users = users.slice().sort((a, b) => b.pointsTotal - a.pointsTotal);
    users = users.map((user, index) => {
        if (userBefore && user.pointsTotal !== userBefore.pointsTotal) {
            place = index + 1;
        }

        let gamedayMoney = 0;
        user.bets.forEach((bet) => {
            if (bet.isMax) {
                const sg = gamedaysMap.get(bet.gameday.id);
                if (sg) {
                    gamedayMoney += (sg.moneyTotal * 0.0025) / sg.winners;
                }
            }
        });

        userBefore = user;

        return {
            ...user,
            place,
            gamedayMoney,
            moneyTotal: user.moneyTotal / 100,
        };
    });

    let percentages: number[] = [];
    let percentage = 0;

    for (let i = 0; i < participants; i++) {
        let countPlace = users.reduce((p, c) => p + (c.place === i + 1 ? 1 : 0), 0);
        percentage = winnings.slice(i, i + countPlace).reduce((p, c) => p + c, 0) / Math.max(countPlace, 1);
        percentages.push(percentage);
    }

    users = users.map((user) => {
        let pottMoney = Math.round(pott * percentages[user.place! - 1]) / 100;
        return {
            ...user,
            pottMoney,
            sumMoney: user.gamedayMoney! + user.moneyTotal + (seasonsGamedays.length === 34 ? pottMoney : 0),
            bets: [],
        };
    });

    return users;
}

export function prepareSeasonGamedays(seasonData: ApiSeasonData) {
    let { users, seasonsGamedays } = seasonData;
    const participants = users.length;

    let renderedBets = users
        .map((user) => user.bets)
        .flat()
        .filter((bet) => bet.gameday.isGameday);

    let gamedays = seasonsGamedays.map((sg) => sg.gameday);

    let pottMoney = 0;

    gamedays = gamedays
        .slice()
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map((gameday) => {
            let bets = renderedBets.filter((bet) => bet.gameday.id === gameday.id);
            let points = bets.reduce((p, c) => p + c.points, 0);
            let missed = bets.reduce((p, c) => p + c.missed, 0);
            let total = 10 * (9 * maxPointsPerGame * participants + missed - points);
            let winners = Math.max(bets.filter((bet) => bet.isMax).length, 1);
            let moneyGameday = Math.round(total) / (400 * winners);
            let moneyPott = Math.round(total * 3) / 400;
            pottMoney += moneyPott;

            return { ...gameday, winners, points, missed, money: { gameday: moneyGameday, pott: moneyPott, total: total / 100 } };
        });

    return {
        users,
        pottMoney,
        gamedays,
        participants,
    };
}
