import * as React from 'react';
import { Gameday, GoalGetter, Match, TableTeam } from './types';
import { Box, Flex, Grid, GridItem, Image } from '@chakra-ui/react';
import { getMatch } from 'functions';
import OpenligaMatchCell from './OpenligaMatchCell';

const gridItemHeight = 14;

interface IOpenligaResultsTableProps {
    currentGameday: Gameday;
    goalgetters: GoalGetter[];
    matches: Match[];
    table: TableTeam[];
    bg: 'white' | 'gray.900';
}

const OpenligaResultsTable: React.FC<IOpenligaResultsTableProps> = (props) => {
    const { bg, currentGameday, goalgetters, matches, table } = props;

    return (
        <Box maxH={640} w={'100%'} overflowY={'auto'}>
            <Grid templateColumns={'repeat(19, 1fr)'} gap={2}>
                <GridStickyItem bg={bg} />
                {table.map(({ teamIconUrl }, idx) => (
                    <GridStickyItem key={idx} bg={bg}>
                        <Image maxH={10} maxW={10} src={teamIconUrl} alt='' />
                    </GridStickyItem>
                ))}
                {table.map(({ teamInfoId: homeId, teamIconUrl: homeIconUrl }) => (
                    <React.Fragment key={homeId}>
                        <GridItem h={gridItemHeight}>
                            <Flex h={'100%'} alignItems={'center'} justifyContent={'center'}>
                                <Image maxW={10} maxH={10} src={homeIconUrl} alt='' />
                            </Flex>
                        </GridItem>
                        {table.map(({ teamInfoId: guestId }, idx) => {
                            const match = getMatch(currentGameday, goalgetters, matches, homeId, guestId);
                            return (
                                <GridItem key={guestId}>
                                    <OpenligaMatchCell key={idx} match={match} />
                                </GridItem>
                            );
                        })}
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
};

interface IGridStickyItemProps {
    children?: React.ReactElement;
    bg: 'white' | 'gray.900';
}

const GridStickyItem: React.FunctionComponent<IGridStickyItemProps> = ({ bg, children }) => (
    <GridItem w={'100%'} position={'sticky'} top={0} bg={bg} h={gridItemHeight} zIndex={1}>
        <Flex h={'100%'} alignItems={'center'} justifyContent={'center'}>
            {children}
        </Flex>
    </GridItem>
);

export default OpenligaResultsTable;
