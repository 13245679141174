export interface ICustomNavLinkProps {
    label: string;
    to: string;
}

// export const userLinks: ICustomNavLinkProps[] = [
//     { label: 'Tippspiel', to: 'graftipp' },
//     { label: 'Zahlungen', to: 'payments' },
// ];

export const adminLinks: ICustomNavLinkProps[] = [{ label: 'Admin', to: 'admin' }];

export const publicLinks: ICustomNavLinkProps[] = [
    { label: 'Tippspiel', to: 'graftipp' },
    { label: 'Zahlungen', to: 'zahlungen' },
    { label: 'Bundesliga', to: 'buli' },
];
