import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Gameday, GoalGetter, Match, ShortcutAndSeasonArgs, ShortcutArgs, TableTeam, TableTeamHelper } from './types';

const openligaApi = createApi({
    reducerPath: 'openliga',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.openligadb.de' }),
    endpoints: (build) => ({
        getCurrentGameday: build.query<Gameday, ShortcutArgs>({
            query: (queryArgs) => `/getcurrentgroup/${queryArgs.leagueShortcut}`,
        }),
        getGoalgetters: build.query<GoalGetter[], ShortcutAndSeasonArgs>({
            query: (queryArgs) => `/getgoalgetters/${queryArgs.leagueShortcut}/${queryArgs.leagueSeason}`,
        }),
        getMatches: build.query<Match[], ShortcutAndSeasonArgs>({
            query: (queryArgs) => `/getmatchdata/${queryArgs.leagueShortcut}/${queryArgs.leagueSeason}`,
        }),
        getTable: build.query<TableTeam[], ShortcutAndSeasonArgs>({
            query: (queryArgs) => `/getbltable/${queryArgs.leagueShortcut}/${queryArgs.leagueSeason}`,
            transformResponse: (rowResult: TableTeamHelper[]) =>
                rowResult.map((team, index) => {
                    let place = index + 1;
                    let prevTeam = rowResult[index - 1];
                    if (index !== 0 && team.points === prevTeam.points && team.goals === prevTeam.goals && team.opponentGoals === prevTeam.opponentGoals) {
                        place = 0;
                    }
                    return { ...team, place, goalRatio: `${team.goals} : ${team.opponentGoals}` };
                }),
        }),
    }),
});

export const { useGetCurrentGamedayQuery, useGetGoalgettersQuery, useGetMatchesQuery, useGetTableQuery } = openligaApi;

export default openligaApi;
