import {
    Center,
    Container,
    Flex,
    Image,
    Progress,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';
import { TableTeam } from './types';

interface IOpenligaTableProps {
    error: FetchBaseQueryError | SerializedError | undefined;
    isLoading: boolean;
    data: {
        table: TableTeam[] | undefined;
    };
}

const OpenligaTable: React.FunctionComponent<IOpenligaTableProps> = (props) => {
    const {
        data: { table },
        isLoading,
        error,
    } = props;
    const bg = useColorModeValue('white', 'black');

    const tableData = React.useMemo<TableTeam[]>(() => table!, [table]);

    return (
        <Container maxW={'container.lg'} p={0}>
            <Flex w={'100%'} boxShadow={'md'} bg={bg} p={2}>
                {isLoading ? <Progress isIndeterminate /> : error ? <Text>Datenabfrage aktuell nicht möglich</Text> : <FormattedTable data={tableData} />}
            </Flex>
        </Container>
    );
};

interface IFormattedTableProps {
    data: TableTeam[];
}

const FormattedTable: React.FunctionComponent<IFormattedTableProps> = (props) => {
    const { data } = props;

    const columnHelper = createColumnHelper<TableTeam>();
    const imgSize = 8;

    const bgThead = useColorModeValue('gray.500', 'gray.200');
    const colorTh = useColorModeValue('white', 'black');

    const columnsBrowser = [
        columnHelper.accessor('place', {
            header: () => <Center>Platz</Center>,
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
        columnHelper.accessor('teamIconUrl', {
            header: () => <Center>Team</Center>,
            cell: (info) => (
                <Center h={imgSize}>
                    <Image maxW={imgSize} maxH={imgSize} src={info.getValue()} alt='' />
                </Center>
            ),
        }),
        columnHelper.accessor('teamName', {
            header: () => '',
        }),
        columnHelper.accessor('matches', {
            header: () => <Center>Spiele</Center>,
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
        columnHelper.accessor('won', {
            header: () => (
                <Center>
                    <Tooltip label='Siege'>S</Tooltip>
                </Center>
            ),
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
        columnHelper.accessor('draw', {
            header: () => (
                <Center>
                    <Tooltip label='Unentschieden'>U</Tooltip>
                </Center>
            ),
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
        columnHelper.accessor('lost', {
            header: () => (
                <Center>
                    <Tooltip label='Niederlage'>N</Tooltip>
                </Center>
            ),
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
        columnHelper.accessor('goalDiff', {
            header: () => (
                <Center>
                    <Tooltip label='Torverhältnis'>TV</Tooltip>
                </Center>
            ),
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
        columnHelper.accessor('goalRatio', {
            header: () => (
                <Center>
                    <Tooltip label='Tordifferenz'>TD</Tooltip>
                </Center>
            ),
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
        columnHelper.accessor('points', {
            header: () => <Center>Punkte</Center>,
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
    ];

    const columnsMobile = [
        columnHelper.accessor('place', {
            header: () => <Center>Pl</Center>,
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
        columnHelper.accessor('teamName', {
            header: () => 'Team',
        }),
        columnHelper.accessor('points', {
            header: () => <Center>Pkt</Center>,
            cell: (info) => <Center>{info.getValue()}</Center>,
        }),
    ];

    const columns = useBreakpointValue({
        base: columnsMobile,
        md: columnsBrowser,
    })!;

    const table = useReactTable({ data, columns, getCoreRowModel: getCoreRowModel() });

    return (
        <Table variant={'striped'} size={'sm'} colorScheme={'gray'}>
            <Thead bg={bgThead}>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <Th key={header.id} py={4} color={colorTh}>
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

export default OpenligaTable;
