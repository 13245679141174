import { Select, Tab, TabList, Tabs, useBreakpointValue } from '@chakra-ui/react';
import { graftippSeason } from 'common/constants';
import React from 'react';
import { ApiUserWithSeasons } from './types';
import { useAppDispatch } from 'app/hooks';
import { history } from 'helpers/history';
import { filterActions } from 'features/filter/filterSlice';

interface IGraftippSeasonTabsProps {
    season: number;
    userWithSeasons: ApiUserWithSeasons;
    isLoading: boolean;
}

const GraftippSeasonTabs: React.FC<IGraftippSeasonTabsProps> = (props) => {
    const { season, userWithSeasons, isLoading } = props;

    const dispatch = useAppDispatch();
    const isMobile = useBreakpointValue({ base: true, xl: false });

    const handleTabsChange = (index: number) => {
        const tempSeason = graftippSeason - index;
        handleChange(tempSeason);
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const tempSeason = Number(event.target.value);
        handleChange(tempSeason);
    };

    const handleChange = (season: number) => {
        const path = history.location?.pathname.split('/').slice(0, -1).join('/') + `/${season}`;
        if (path && history.navigate) {
            history.navigate(path);
            dispatch(filterActions.changeGraftippSeason(season));
        }
    };

    return (
        <>
            <Tabs index={graftippSeason - season} onChange={handleTabsChange} display={{ base: 'none', md: 'block' }} isFitted={true}>
                <TabList>
                    {userWithSeasons.seasons.map((season) => (
                        <Tab key={season.id} isDisabled={isLoading}>
                            {isMobile ? season.year : season.description}
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
            <Select value={season} onChange={handleSelectChange} display={{ base: 'block', md: 'none' }}>
                {userWithSeasons.seasons.map((season) => (
                    <option key={season.id} value={season.year}>
                        {season.description}
                    </option>
                ))}
            </Select>
        </>
    );
};

export default GraftippSeasonTabs;
