import { Box, Flex, Progress, Show, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue } from '@chakra-ui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ApexOptions } from 'apexcharts';
import { ErrorBox } from 'components/controls';
import React from 'react';
import Chart from 'react-apexcharts';
import { MdAreaChart, MdPieChart } from 'react-icons/md';
import { Match } from './types';

interface IOpenligaStatisticsProps {
    isLoading: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
    data: {
        matches: Match[] | undefined;
    };
}

const OpenligaStatistics: React.FC<IOpenligaStatisticsProps> = (props) => {
    const {
        data: { matches },
        isLoading,
        error,
    } = props;

    const bg = useColorModeValue('white', 'gray.900');

    return (
        <Flex w={'100%'} p={4} boxShadow={'md'} bg={bg}>
            {isLoading ? (
                <Progress isIndeterminate />
            ) : error ? (
                <ErrorBox error={error} />
            ) : matches ? (
                <Statistics matches={matches} />
            ) : (
                <ErrorBox error='Unbekannter Fehler' />
            )}
        </Flex>
    );
};

class FormattedMatch {
    constructor(
        public p1: number,
        public p2: number,
    ) {}
}

interface IStatisticsProps {
    matches: Match[];
}

const Statistics: React.FC<IStatisticsProps> = (props) => {
    const { matches } = props;
    let maxGoals: Array<number> = [0, 0];
    var list = new Array<FormattedMatch>();

    let formattedMatches = matches.reduce<FormattedMatch[]>((p, c) => {
        if (!c.matchIsFinished) return p;
        let result = c.matchResults.find((x) => x.resultName === 'Endergebnis');
        if (result) {
            maxGoals[0] = Math.max(maxGoals[0], result.pointsTeam1 ?? 0);
            maxGoals[1] = Math.max(maxGoals[1], result.pointsTeam2 ?? 0);
        }
        p.push(new FormattedMatch(result?.pointsTeam1 ?? -1, result?.pointsTeam2 ?? -1));
        return p;
    }, list);

    return (
        <>
            <Show above='xl'>
                <Tabs w={'100%'} isFitted>
                    <TabList mb={2}>
                        <Tab>
                            <MdPieChart fontSize={'1.5rem'} />
                            <Box ml={2}>Ergebnisdiagramm</Box>
                        </Tab>
                        <Tab>
                            <MdAreaChart fontSize={'1.5rem'} />
                            <Box ml={2}>Heat-Map</Box>
                        </Tab>
                    </TabList>
                    <TabPanels border='1px solid' borderColor={useColorModeValue('primary.600', 'primary.300')}>
                        <TabPanel>
                            <ResultPie matches={formattedMatches} />
                        </TabPanel>
                        <TabPanel>
                            <ResultArea matches={formattedMatches} maxGoals={maxGoals} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Show>
            <Show below='xl'>
                <Text>Diese Seite kann in der mobilen Ansicht nicht dargestellt werden</Text>
            </Show>
        </>
    );
};

interface IResultProps {
    matches: FormattedMatch[];
    maxGoals?: Array<number>;
}

const ResultPie: React.FC<IResultProps> = (props) => {
    const { matches } = props;
    let resultMap = new Map<string, number>();
    let resultMapOthers = new Map<string, number>();
    let gamesTreshold = Math.floor(matches.length / 25);

    matches.forEach((match) => {
        let key = `${match.p1}:${match.p2}`;
        if (resultMap.has(key)) {
            resultMap.set(key, (resultMap.get(key) ?? 0) + 1);
        } else {
            resultMap.set(key, 1);
        }
    });

    resultMap = new Map([...resultMap.entries()].sort((a, b) => b[1] - a[1]));

    resultMap.forEach((value, key) => {
        if (value <= gamesTreshold && value < 10) {
            if (resultMap.has('Sonstige')) {
                resultMap.set('Sonstige', (resultMap.get('Sonstige') ?? 0) + value);
            } else {
                resultMap.set('Sonstige', value);
            }
            resultMapOthers.set(key, value);
            resultMap.delete(key);
        }
    });

    const optionsMain: ApexOptions = {
        noData: {
            text: 'Es liegen noch keine Ergebnisse vor.',
            style: {
                fontSize: '2rem',
            },
        },
        labels: [...resultMap.keys()],
        dataLabels: {
            enabled: true,
            formatter: (val: any, opts: any) => {
                return opts.w.globals.labels[opts.seriesIndex];
            },
        },
        legend: {
            show: false,
        },
    };

    const optionsOthers: ApexOptions = {
        labels: [...resultMapOthers.keys()],
        dataLabels: {
            enabled: true,
            formatter: (val: any, opts: any) => {
                return opts.w.globals.labels[opts.seriesIndex];
            },
        },
        legend: {
            show: false,
        },
        theme: {
            monochrome: {
                enabled: true,
            },
        },
    };

    return (
        <Box position={'relative'} minH={500}>
            <Chart
                options={optionsMain}
                series={Array.from(resultMap.values())}
                type='donut'
                height={550}
                width={550}
                style={{ position: 'absolute', display: 'flex', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
            />
            <Chart
                options={optionsOthers}
                series={Array.from(resultMapOthers.values())}
                type='donut'
                height={370}
                width={370}
                style={{ position: 'absolute', display: 'flex', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
            />
        </Box>
    );
};

class AreaEntry {
    constructor(
        public x: string,
        public y: number,
    ) {}
}

class AreaData {
    constructor(
        public name: string,
        public data: Array<AreaEntry>,
    ) {}
}

const ResultArea: React.FC<IResultProps> = (props) => {
    const { matches, maxGoals } = props;
    let series = new Array<AreaData>();

    const strokeColor = useColorModeValue('#ffffff', '#171923');
    const textColor = useColorModeValue('#fff', '#2d3748');
    const colorRange1 = useColorModeValue('#e53e3e', '#feb2b2');
    const colorRange2 = useColorModeValue('#d69e2e', '#faf089');
    const colorRange3 = useColorModeValue('#3182ce', '#90cdf4');
    const colorRange4 = useColorModeValue('#38a169', '#9ae6b4');
    const axisColor = useColorModeValue('#2d3748', '#fff');

    if (!maxGoals) return null;

    for (let guest = 0; guest <= maxGoals[1]; guest++) {
        let data = new Array<AreaEntry>();
        for (let home = 0; home <= maxGoals[0]; home++) {
            let filtered = matches.filter((x) => x.p1 === home && x.p2 === guest);
            data.push(new AreaEntry(`Heim ${home}`, filtered.length));
        }
        series.push(new AreaData(`Gast ${guest}`, data));
    }

    const options: ApexOptions = {
        noData: {
            text: 'Es liegen noch keine Ergebnisse vor.',
            style: {
                fontSize: '2rem',
            },
        },
        stroke: {
            show: true,
            colors: [strokeColor],
            width: 5,
        },
        plotOptions: {
            heatmap: {
                enableShades: false,
                radius: 0,
                colorScale: {
                    ranges: [
                        { from: 0, to: 0, color: strokeColor, foreColor: strokeColor, name: ' ' },
                        { from: 1, to: 3, color: colorRange1, foreColor: textColor, name: 'Ausnahme' },
                        { from: 4, to: 10, color: colorRange2, foreColor: textColor, name: 'Selten' },
                        { from: 11, to: 20, color: colorRange3, foreColor: textColor, name: 'Oft' },
                        { from: 21, to: 100, color: colorRange4, foreColor: textColor, name: 'Sehr oft' },
                    ],
                },
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '20px',
            },
        },
        legend: {
            fontSize: '16px',
            labels: {
                colors: axisColor,
            },
        },
        xaxis: {
            labels: {
                style: {
                    colors: axisColor,
                    fontSize: '16px',
                    fontWeight: 100,
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: axisColor,
                    fontSize: '16px',
                },
            },
        },
        chart: {
            toolbar: {
                show: false,
            },
        },
        tooltip: {
            enabled: false,
        },
    };

    return (
        <Box position={'relative'} minH={500}>
            <Chart
                options={options}
                series={series}
                type='heatmap'
                height={500}
                width={700}
                style={{ position: 'absolute', display: 'flex', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}
            />
        </Box>
    );
};

export default OpenligaStatistics;
