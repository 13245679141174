import { Role } from 'common/enums';
import { Layout, PrivateRoute } from 'components/main';
import { GraftippLayout, GraftippPayments } from 'features/graftipp';
import { OpenligaLayout } from 'features/openliga';
import { history } from 'helpers/history';
import { Home } from 'pages';
import React from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

const App: React.FC = () => {
    // init custom history object to allow navigation from
    // anywhere in the react app (inside or outside components)
    history.location = useLocation();
    history.navigate = useNavigate();

    return (
        <Layout>
            <Routes>
                {/* private */}
                <Route element={<PrivateRoute />}>
                    <Route index element={<Home />} />
                    <Route path='buli/*' element={<OpenligaLayout />} />
                </Route>
                {/* private with roles */}
                <Route element={<PrivateRoute roles={Role.Admin} />}>
                    <Route path='admin/*' element={<div>Admin Layout</div>} />
                </Route>
                {/* public */}
                <Route path='graftipp/*' element={<GraftippLayout />} />
                <Route path='zahlungen' element={<GraftippPayments />} />
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
        </Layout>
    );
};

export default App;
