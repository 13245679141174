import { Heading, Progress, Select, Stack, useBreakpointValue } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { history } from 'helpers/history';
import React, { useEffect } from 'react';
import { Route, Routes, useMatch } from 'react-router-dom';
import { useGetSeasonDataQuery, useGetUserWithSeasonsQuery } from './graftippApi';
import { ErrorBox, NavLinkButton } from 'components/controls';
import { filterActions } from 'features/filter/filterSlice';
import GraftippSeasonTabs from './GraftippSeasonTabs';
import GraftippTotal from './GraftippTotal';
import GraftippGamedays from './GraftippGamedays';

const GraftippLayout: React.FC = () => {
    const authUser = useAppSelector((state) => state.authentication.user);
    const { season, section } = useAppSelector((state) => state.filter.graftipp);
    const dispatch = useAppDispatch();

    const {
        data: userWithSeasons,
        isLoading: isLoadingUWS,
        error: errorUWS,
        isUninitialized,
    } = useGetUserWithSeasonsQuery({ userId: authUser?.id }, { skip: !authUser?.id });
    const { data: seasonData, isLoading: isLoadingSD, error: errorSD, isFetching } = useGetSeasonDataQuery({ season });

    const error = errorSD || errorUWS;
    const isLoading = isLoadingSD || isLoadingUWS;
    const isIndex = useMatch('/graftipp');

    const seasonString = `${season}/${(season % 100) + 1}`;
    const heading = useBreakpointValue({
        base: <Heading size={'md'}>Tippspiel {seasonString}</Heading>,
        md: <Heading size={{ md: 'lg', lg: '2xl' }}>Das Tippspiel in der Saison {seasonString}</Heading>,
    });

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const graftippSection = event.target.value as 'gesamt' | 'spieltage' | 'statistiken';

        const path = `/graftipp/${graftippSection}/${season}`;
        if (history.navigate) {
            history.navigate(path);
        }

        dispatch(filterActions.changeGraftippSection(graftippSection));
    };

    useEffect(() => {
        if (isIndex && history.navigate) {
            history.navigate(`/graftipp/${section}/${season}`);
        }
    }, [isIndex, season, section]);

    return (
        <Stack direction={'column'} w={'100%'} spacing={4}>
            {heading}
            <Stack direction={'row'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                <NavLinkButton to={`gesamt/${season}`} minW={150}>
                    Gesamtstand
                </NavLinkButton>
                <NavLinkButton to={`spieltage/${season}`} minW={150}>
                    Spieltage
                </NavLinkButton>
                <NavLinkButton to={`statistiken/${season}`} minW={150}>
                    Statistiken
                </NavLinkButton>
            </Stack>
            <Select placeholder='Bereich wählen' onChange={handleSelectChange} display={{ base: 'inline-block', md: 'none' }}>
                <option value='gesamt'>Gesamtstand</option>
                <option value='spieltage'>Spieltage</option>
            </Select>
            {error ? (
                <ErrorBox error={error} />
            ) : isLoading ? (
                <Progress isIndeterminate />
            ) : isUninitialized ? (
                <ErrorBox error='Es gibt ein Problem mit dem User' />
            ) : seasonData && userWithSeasons ? (
                <Stack direction={'column'} w={'100%'} spacing={4}>
                    {!isIndex && <GraftippSeasonTabs season={season} userWithSeasons={userWithSeasons} isLoading={isFetching} />}
                    <Routes>
                        <Route path='gesamt/:season' element={<GraftippTotal seasonData={seasonData} />} />
                        <Route path='spieltage/:season' element={<GraftippGamedays seasonData={seasonData} />} />
                    </Routes>
                </Stack>
            ) : (
                <ErrorBox error='Unbekannter Fehler' />
            )}
        </Stack>
    );
};

export default GraftippLayout;
