import { Button, ButtonProps } from '@chakra-ui/react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type INavLinkButtonProps = ButtonProps &
    NavLinkProps & {
        children: string;
    };

export function NavLinkButton(props: INavLinkButtonProps) {
    const { target, children, leftIcon, isLoading, minW, ...rest } = props;

    return (
        <NavLink {...rest}>
            {({ isActive }) => (
                <Button variant={isActive ? 'solid' : 'outline'} leftIcon={leftIcon} isLoading={isLoading} minW={minW} {...rest}>
                    {children}
                </Button>
            )}
        </NavLink>
    );
}
