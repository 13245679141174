import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from 'common/constants';
import { ApiPamentRaw, ApiPayment, ApiSeasonArgs, ApiSeasonData, ApiSeasonDataRaw, ApiUserArgs, ApiUserWithSeasons } from './types';

const graftippApi = createApi({
    reducerPath: 'graftipp',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (build) => ({
        getPayments: build.query<ApiPayment[], void>({
            query: () => '/payments?populated=true',
            transformResponse: (payments: ApiPamentRaw[]) => {
                return payments.map((payment) => {
                    const amount = parseFloat(payment.amount);

                    const description = amount > 0 ? payment.paymentType.textPositive : payment.paymentType.textNegative;
                    const isFirst = payment.paymentType.title === 'Anfangskontostand';
                    const paymentType = { ...payment.paymentType, description, isFirst };

                    const user = { ...payment.user, fullName: payment.user.fullName2 };

                    return { ...payment, amount, user, paymentType };
                });
            },
        }),
        getSeasonData: build.query<ApiSeasonData, ApiSeasonArgs>({
            query: (seasonArgs) => `/seasons/${seasonArgs.season}?populated=true`,
            transformResponse: (seasonDataRaw: ApiSeasonDataRaw) => {
                const users = seasonDataRaw.users.map((user) => {
                    return {
                        ...user,
                        pointsTotal: user.pointsTotal ?? 0,
                        gamedaysTotal: user.gamedaysTotal ?? 0,
                        bonusTotal: user.bonusTotal ?? 0,
                        missedTotal: user.missedTotal ?? 0,
                        moneyTotal: user.moneyTotal ?? 0,
                        gamedayMoney: 0,
                        pottMoney: 0,
                        sumMoney: 0,
                        bets: user.bets ?? [],
                    };
                });

                return { ...seasonDataRaw, users };
            },
        }),
        getUserWithSeasons: build.query<ApiUserWithSeasons, ApiUserArgs>({
            query: (userArgs) => `/users/${userArgs.userId}/seasons`,
        }),
    }),
});

export const { useGetPaymentsQuery, useGetSeasonDataQuery, useGetUserWithSeasonsQuery } = graftippApi;

export default graftippApi;
