import { Avatar, Center, Grid, GridItem, HStack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { MdCalendarToday, MdGroup, MdPlace, MdSportsSoccer } from 'react-icons/md';
import { MatchExtended } from './types';

interface IOpenligaMatchFactProps {
    match: MatchExtended;
}

const OpenligaMatchFact: React.FC<IOpenligaMatchFactProps> = (props) => {
    const { goals: goalsTemp, group, location, matchDateTime, matchIsFinished: finish, matchResults, numberOfViewers, team1, team2 } = props.match;
    const date = new Date(matchDateTime);
    const dateString = `${date.toLocaleString('de-DE', {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    })} Uhr`;
    const goals = goalsTemp.sort((a, b) => a.matchMinute - b.matchMinute);
    const resultEnd = matchResults.find((x) => x.resultName === 'Endergebnis');
    const resultHalf = matchResults.find((x) => x.resultName === 'Halbzeit');

    const bgThead = useColorModeValue('secondary.500', 'secondary.200');
    const colorTh = useColorModeValue('white', 'black');

    return (
        <Grid templateColumns={'repeat(7, 1fr)'} gap={4}>
            <GridItem colSpan={7}>
                <Text fontSize={'2xl'} textAlign={'center'}>
                    {team1.teamName} - {team2.teamName}
                </Text>
            </GridItem>
            <GridItem colSpan={finish ? 3 : 7}>
                <ListItem icon={<MdCalendarToday />} primary={group.groupName} secondary={dateString} />
            </GridItem>
            {finish && (
                <GridItem colSpan={4} rowSpan={4}>
                    <Table size={'sm'} variant={'striped'} colorScheme={'secondary'}>
                        <colgroup>
                            <col width='12%' />
                            <col width='30%' />
                            <col width='16%' />
                            <col width='30%' />
                            <col width='12%' />
                        </colgroup>
                        <Thead bg={bgThead}>
                            <Tr>
                                <Th aria-label='minute'></Th>
                                <Th aria-label='teamHome'>
                                    <Text align='right' color={colorTh}>
                                        {team1.shortName}
                                    </Text>
                                </Th>
                                <Th aria-label='action' />
                                <Th aria-label='teamGuest' color={colorTh}>
                                    {team2.shortName}
                                </Th>
                                <Th color={colorTh}>
                                    <Center>Anm.</Center>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {goals.length === 0 && (
                                <Tr>
                                    <Td colSpan={5}>
                                        <Center>
                                            <em>TORE: Fehlanzeige</em>
                                        </Center>
                                    </Td>
                                </Tr>
                            )}
                            {goals.map((goal, index) => {
                                const { goalGetterName, goalID, isOwnGoal, isPenalty, matchMinute, scoreTeam1, scoreTeam2 } = goal;
                                const prevGoal = index > 0 ? goals[index - 1].scoreTeam1 : undefined;
                                const nowGoal = scoreTeam1;

                                return (
                                    <Tr key={goalID}>
                                        <Td>
                                            <Text align='center' fontWeight='bold'>
                                                {matchMinute}.
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text align='right'>{prevGoal !== nowGoal ? goalGetterName : null}</Text>
                                        </Td>
                                        <Td>
                                            <Text align='center'>
                                                {scoreTeam1} : {scoreTeam2}
                                            </Text>
                                        </Td>
                                        <Td>{prevGoal === nowGoal ? goal.goalGetterName : null}</Td>
                                        <Td>
                                            <Center>{isPenalty ? '(EL)' : isOwnGoal ? '(ET)' : null}</Center>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </GridItem>
            )}
            <GridItem colSpan={finish ? 3 : 7}>
                <ListItem
                    icon={<MdSportsSoccer fontSize='1.5rem' />}
                    primary={finish ? `Endergebnis: ${resultEnd?.pointsTeam1}:${resultEnd?.pointsTeam2}` : 'Ergebnis offen'}
                    secondary={finish ? `Halbzeit: ${resultHalf?.pointsTeam1}:${resultHalf?.pointsTeam2}` : undefined}
                />
            </GridItem>
            <GridItem colSpan={finish ? 3 : 7}>
                <ListItem icon={<MdPlace fontSize='1.5rem' />} primary={location?.locationCity ?? 'kein Ort angegeben'} secondary={location?.locationStadium} />
            </GridItem>
            <GridItem colSpan={finish ? 3 : 7}>
                <ListItem icon={<MdGroup fontSize='1.5rem' />} primary='Zuschauer' secondary={numberOfViewers?.toString() ?? 'nicht angegeben'} />
            </GridItem>
        </Grid>
    );
};

interface IListItemProps {
    icon: React.ReactElement;
    primary: string;
    secondary?: string;
}

const ListItem: React.FunctionComponent<IListItemProps> = ({ icon, primary, secondary }) => (
    <HStack spacing={4}>
        <Avatar bg={'secondary.600'} color={'white'} icon={icon} />
        <VStack alignItems={'flex-start'} spacing={0}>
            <Text as={'b'} fontSize={'md'}>
                {primary}
            </Text>
            {secondary && (
                <Text fontSize={'xs'} color={'gray.500'}>
                    {secondary}
                </Text>
            )}
        </VStack>
    </HStack>
);

export default OpenligaMatchFact;
