import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiUser } from 'common/types';
import type { AuthState } from './types';
import { Role } from '../../common/enums';

const currentUser: ApiUser = {
    id: '334a1cc6-657b-4b2c-a1d5-a73a60fdb9a3',
    firstName: 'Jens',
    lastName: 'Bleumer',
    fullName: 'Jens Bleumer',
    userName: 'bleumi',
    email: 'jens.bleumer@gmail.com',
    role: Role.User,
};

// create slice
const name = 'authentication';
const initialState: AuthState = { user: currentUser };
const reducers = createReducers();
const extraActions = createExtraActions();
const authSlice = createSlice({ name, initialState, reducers });

// exports
export const authActions = { ...authSlice.actions, ...extraActions };
export default authSlice.reducer;

// implementation

function createReducers() {
    return {
        setAuthData,
        clearAuthData,
    };

    function setAuthData(state: AuthState, action: PayloadAction<ApiUser>) {
        state.user = action.payload;
    }

    function clearAuthData(state: AuthState) {
        state.user = null;
    }
}

function createExtraActions() {
    return {};
}
