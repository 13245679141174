export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce(
        (groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        },
        {} as Record<K, T[]>,
    );

export function groupByToMap<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
        var key = grouper(item);
        if (!store.has(key)) {
            store.set(key, [item]);
        } else {
            store.get(key)?.push(item);
        }
        return store;
    }, new Map<K, V[]>());
}
