import {
    Center,
    Container,
    Flex,
    Show,
    Table,
    TableCellProps,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
// import { useAppSelector } from "app/hooks";
import React, { createContext, useContext, useMemo } from 'react';
import { ApiSeasonData, ApiSeasonUser } from './types';
import { prepareSeasonUsers } from 'functions/prepareData';

interface IGraftippTotalProps {
    seasonData: ApiSeasonData;
}

const GraftippTotal: React.FC<IGraftippTotalProps> = (props) => {
    const { seasonData } = props;
    // const authUser = useAppSelector((state) => state.authentication.user);

    const bgThead = useColorModeValue('primary.700', 'primary.300');
    const colorTdMoney = useColorModeValue('gray.400', 'gray.500');

    const seasonDataHelper = useMemo(() => {
        const countGamedays = seasonData.seasonsGamedays.length;
        const users = prepareSeasonUsers(seasonData);
        return { ...seasonData, users, isFinished: countGamedays === 34, countGamedays };
    }, [seasonData]);

    const winLine = seasonDataHelper.isFinished ? (
        <Center>Gewinne (Saisonende)</Center>
    ) : (
        <Center>
            Gewinne (<em>nach {seasonDataHelper.countGamedays} von 34 Spieltagen</em>)
        </Center>
    );

    return (
        <Container maxW={'container.lg'} variant={'graftipp'} border='1px solid' borderColor={bgThead}>
            <Flex w={'100%'} boxShadow={'md'} p={2}>
                <Table variant={'striped'} size={'sm'} colorScheme={'primary'}>
                    <Thead bg={bgThead} display={{ base: 'none', lg: 'table-header-group' }}>
                        <Tr>
                            <CustomTh rowSpan={2}>
                                <Center>Platz</Center>
                            </CustomTh>
                            <CustomTh rowSpan={2}>Name</CustomTh>
                            <CustomTh rowSpan={2}>
                                <Center>Punkte</Center>
                            </CustomTh>
                            <CustomTh colSpan={3}>{winLine}</CustomTh>
                            <CustomTh rowSpan={2} isNumeric>
                                Betrag
                            </CustomTh>
                        </Tr>
                        <Tr>
                            <CustomTh isNumeric>
                                <Tooltip label='Spieltagskosten' placement='top'>
                                    ST (Kosten)
                                </Tooltip>
                            </CustomTh>
                            <CustomTh isNumeric>
                                <Tooltip label='Spieltagsgewinne' placement='top'>
                                    ST (Gewinn)
                                </Tooltip>
                            </CustomTh>
                            <CustomTh isNumeric>
                                <Tooltip label={seasonDataHelper.isFinished ? 'Gewinn' : 'möglicher Gewinn'} placement='top'>
                                    Pott (Gewinn)
                                </Tooltip>
                            </CustomTh>
                        </Tr>
                    </Thead>
                    <Thead bg={bgThead} display={{ base: 'table-header-group', lg: 'none' }}>
                        <Tr>
                            <CustomTh>
                                <Center>Pl</Center>
                            </CustomTh>
                            <CustomTh>Name</CustomTh>
                            <CustomTh>
                                <Center>Pkt</Center>
                            </CustomTh>
                            <CustomTh isNumeric>Konto</CustomTh>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {seasonDataHelper.users.map((user: ApiSeasonUser, index: number) => {
                            const { fullName2, gamedayMoney, id, moneyTotal, place, pointsTotal, pottMoney, sumMoney, userName } = user;
                            const isCurrent = false; //authUser?.id === id;
                            const moneyLine =
                                place! <= seasonData.seasonPlaces.length && seasonDataHelper.users[index + 1].place! > seasonData.seasonPlaces.length;

                            return (
                                <CustomTr key={id} isCurrent={isCurrent} moneyLine={moneyLine}>
                                    <CustomTd>
                                        {index === 0 || (index > 0 && place !== seasonDataHelper.users[index - 1].place) ? <Center>{place}</Center> : ''}
                                    </CustomTd>
                                    <CustomTd>
                                        <Show above='lg'>
                                            {fullName2} <Text as={'sup'}>({userName})</Text>
                                        </Show>
                                        <Show below='lg'>{userName}</Show>
                                    </CustomTd>
                                    <CustomTd>
                                        <Center>{pointsTotal}</Center>
                                    </CustomTd>
                                    <CustomTd color={moneyTotal < 0 ? 'red.500' : ''} display={{ base: 'none', md: 'table-cell' }} isNumeric>
                                        {moneyTotal.toFixed(2)}€
                                    </CustomTd>
                                    <CustomTd color={gamedayMoney > 0 ? '' : colorTdMoney} display={{ base: 'none', md: 'table-cell' }} isNumeric>
                                        {gamedayMoney!.toFixed(2)}€
                                    </CustomTd>
                                    <CustomTd color={pottMoney! > 0 ? '' : colorTdMoney} display={{ base: 'none', md: 'table-cell' }} isNumeric>
                                        {pottMoney!.toFixed(2)}€
                                    </CustomTd>
                                    <CustomTd color={sumMoney! < 0 ? 'red.500' : ''} isNumeric>
                                        {sumMoney!.toFixed(2)}€
                                    </CustomTd>
                                </CustomTr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Flex>
            {/* <pre>{JSON.stringify(seasonDataHelper, null, 2)}</pre> */}
        </Container>
    );
};

interface ICellProps extends TableCellProps {
    children: string | string[] | React.ReactElement | React.ReactElement[];
}

const CustomTh: React.FC<ICellProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Th color={useColorModeValue('white', 'gray.700')} py={2} {...rest}>
            {children}
        </Th>
    );
};

const TrContext = createContext({ isCurrent: false, moneyLine: false });

interface ICustomTrProps {
    isCurrent: boolean;
    moneyLine: boolean;
    children: React.ReactElement[];
}

const CustomTr: React.FC<ICustomTrProps> = (props) => {
    const { isCurrent, moneyLine, children } = props;
    return (
        <TrContext.Provider value={{ isCurrent, moneyLine }}>
            <Tr>{children}</Tr>
        </TrContext.Provider>
    );
};

const CustomTd: React.FC<ICellProps> = (props) => {
    const { children, ...rest } = props;
    const { isCurrent, moneyLine } = useContext(TrContext);
    const { colorMode } = useColorMode();

    return (
        <Td
            fontWeight={isCurrent ? 'bold' : ''}
            borderBottom={
                moneyLine ? `2px solid ${colorMode === 'light' ? 'var(--chakra-colors-primary-700)' : 'var(--chakra-colors-primary-200)'}!important` : ''
            }
            {...rest}
        >
            {children}
        </Td>
    );
};

export default GraftippTotal;
