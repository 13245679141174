import {
    Box,
    Center,
    Container,
    Flex,
    Grid,
    GridItem,
    IconButton,
    Show,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tooltip,
    Tr,
    VStack,
    useColorMode,
    useColorModeValue,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { BiSolidCircleQuarter, BiSolidCircleThreeQuarter } from 'react-icons/bi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { ApiGameday, ApiSeasonData, ApiSeasonUser } from './types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { prepareSeasonGamedays } from 'functions/prepareData';
import { filterActions } from 'features/filter/filterSlice';
import { ApiUser } from 'common/types';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

const maxPointsPerGame = 3;

interface IGraftippGamedaysProps {
    seasonData: ApiSeasonData;
}

const GraftippGamedays: React.FC<IGraftippGamedaysProps> = (props) => {
    const { seasonData } = props;
    const authUser = useAppSelector((state) => state.authentication.user);

    const seasonDataHelper = useMemo(() => {
        return prepareSeasonGamedays(seasonData);
    }, [seasonData]);

    return (
        <>
            <Show above='xl'>
                <DesktopView data={seasonDataHelper} authUser={authUser} />
            </Show>
            <Show below='xl'>
                <MobileView data={seasonDataHelper} authUser={authUser} />
            </Show>
        </>
    );
};

interface IViewProps {
    data: {
        users: ApiSeasonUser[];
        gamedays: ApiGameday[];
        pottMoney: number;
        participants: number;
    };
    authUser: ApiUser | null;
}

const DesktopView: React.FC<IViewProps> = (props) => {
    const {
        data: { users, gamedays, pottMoney, participants },
    } = props;
    const { round } = useAppSelector((state) => state.filter.graftipp);

    const { colorMode } = useColorMode();

    const bgTheadDark = useColorModeValue('primary.700', 'primary.300');
    const bgTheadMiddle = useColorModeValue('primary.600', 'primary.200');
    const bgTheadLight = useColorModeValue('primary.500', 'primary.100');
    const colorThead = useColorModeValue('white', 'gray.700');

    const dispatch = useAppDispatch();

    const setRound = (round: 0 | 17) => {
        dispatch(filterActions.changeGraftippRound(round));
    };

    return (
        <Container maxW={'container.2xl'} p={0} variant={'graftipp'}>
            <Flex w={'100%'} boxShadow={'md'} p={4} borderRadius={10}>
                <Stack direction={'column'} w={'100%'} spacing={4}>
                    <Stack direction={'row'} spacing={2}>
                        <IconButton aria-label='hinrunde' isActive={round === 0} onClick={() => setRound(0)} icon={<FaChevronLeft />} />
                        <IconButton
                            aria-label='rueckrunde'
                            isActive={round === 17}
                            onClick={() => setRound(17)}
                            icon={<FaChevronRight />}
                            isDisabled={gamedays.length <= 17}
                        />
                    </Stack>
                    <Table size={'sm'} variant={'striped'}>
                        <colgroup>
                            <col width='10%' />
                        </colgroup>
                        <Thead>
                            <Tr>
                                <Th bg={bgTheadDark} color={colorThead} borderTopLeftRadius={10}>
                                    <Text as={'samp'} fontSize={'lg'}>
                                        {round === 0 ? 'Hin' : 'Rück'}runde
                                    </Text>
                                </Th>
                                {gamedays.slice(round, round + 17).map(({ id, title }) => (
                                    <Th key={id} bg={bgTheadMiddle} color={colorThead} p={3}>
                                        <RotatedSpan>{title}</RotatedSpan>
                                    </Th>
                                ))}
                                <Th bg={bgTheadLight} color={colorThead}>
                                    <RotatedSpan>Bonus</RotatedSpan>
                                </Th>
                                <Th bg={bgTheadDark} color={colorThead} borderTopRightRadius={10}>
                                    <RotatedSpan>Summe</RotatedSpan>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users
                                .slice()
                                .sort((a, b) => b.pointsTotal - a.pointsTotal)
                                .map((user, index1) => {
                                    let { bets, bonusTotal, pointsTotal, id, userName } = user;
                                    bets = bets.filter((bet) => bet.gameday.isGameday);
                                    const isCurrent = false; //authUser?.id === id;

                                    return (
                                        <Tr key={id}>
                                            <Td
                                                fontWeight={isCurrent ? 'bold' : ''}
                                                bg={index1 % 2 === 0 ? (colorMode === 'light' ? 'primary.200!important' : 'primary.800!important') : ''}
                                                borderRight={isCurrent ? '3px solid' : ''}
                                                borderRightColor={isCurrent ? (colorMode === 'light' ? 'gray.700!' : 'white!') : ''}
                                            >
                                                {userName}
                                            </Td>
                                            {bets.slice(round, round + 17).map((bet, index2) => {
                                                const { id, isMax, missed, points } = bet;
                                                const baseMoney = -0.9 * maxPointsPerGame;
                                                const pointsMoney = 0.1 * points;
                                                const missedMoney = -0.1 * missed;
                                                const gamedayMoney = props.data.gamedays[index2].money!.gameday;
                                                const sumMoney = Math.round(100 * (baseMoney + pointsMoney + missedMoney + (isMax ? gamedayMoney : 0))) / 100;

                                                return (
                                                    <Td
                                                        key={id}
                                                        p={0}
                                                        fontWeight={isCurrent ? 700 : ''}
                                                        _hover={{
                                                            bg:
                                                                index1 % 2 === 0
                                                                    ? colorMode === 'light'
                                                                        ? 'primary.50!important'
                                                                        : 'primary.600!important'
                                                                    : colorMode === 'light'
                                                                    ? 'gray.100!important'
                                                                    : 'gray.700!important',
                                                        }}
                                                    >
                                                        <Tooltip
                                                            hasArrow
                                                            label={
                                                                <DesktopTooltip
                                                                    section='body'
                                                                    baseMoney={baseMoney}
                                                                    points={points}
                                                                    pointsMoney={pointsMoney}
                                                                    missed={missed}
                                                                    missedMoney={missedMoney}
                                                                    isMax={isMax}
                                                                    gamedayMoney={gamedayMoney}
                                                                    sumMoney={sumMoney}
                                                                />
                                                            }
                                                        >
                                                            <Center>
                                                                <Box
                                                                    w='50%'
                                                                    py={1}
                                                                    textAlign='center'
                                                                    bg={isMax && isCurrent ? 'primary.500' : ''}
                                                                    border={isMax ? '1px solid' : ''}
                                                                    borderColor={bgTheadMiddle}
                                                                    fontWeight={isMax ? 'bold' : ''}
                                                                    color={isMax && isCurrent ? 'white' : ''}
                                                                    borderRadius={4}
                                                                >
                                                                    {points}
                                                                </Box>
                                                            </Center>
                                                        </Tooltip>
                                                    </Td>
                                                );
                                            })}
                                            <Td
                                                fontWeight={isCurrent ? 'bold' : ''}
                                                bg={index1 % 2 === 0 ? (colorMode === 'light' ? 'primary.50!important' : 'primary.600!important') : ''}
                                                borderColor={colorMode === 'light' ? 'primary.50!important' : 'primary.600!important'}
                                            >
                                                <Center fontWeight={500}>{bonusTotal}</Center>
                                            </Td>
                                            <Td
                                                fontWeight={isCurrent ? 'bold' : ''}
                                                bg={index1 % 2 === 0 ? (colorMode === 'light' ? 'primary.200!important' : 'primary.800!important') : ''}
                                            >
                                                <Center fontWeight={700}>{pointsTotal}</Center>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Td bg={bgTheadDark} color={colorThead}>
                                    ST-Einnahmen
                                </Td>
                                {gamedays.slice(round, round + 17).map((gameday) => {
                                    const { id, money, points, missed } = gameday;
                                    const baseMoney = 0.9 * participants * maxPointsPerGame;
                                    const pointsMoney = -0.1 * points!;
                                    const missedMoney = 0.1 * missed!;
                                    const sumMoney = baseMoney + pointsMoney + missedMoney;

                                    return (
                                        <Td key={id} bg={bgTheadMiddle} color={colorThead}>
                                            <Tooltip
                                                hasArrow
                                                placement='top'
                                                label={
                                                    <DesktopTooltip
                                                        section='footer'
                                                        baseMoney={baseMoney}
                                                        points={points ?? 0}
                                                        pointsMoney={pointsMoney}
                                                        missed={missed ?? 0}
                                                        missedMoney={missedMoney}
                                                        sumMoney={sumMoney}
                                                    />
                                                }
                                            >
                                                <Box textAlign={'center'}>{money!.total.toFixed(2)}€</Box>
                                            </Tooltip>
                                        </Td>
                                    );
                                })}
                                <Td colSpan={2} rowSpan={3} borderBottomRightRadius={10} bg={bgTheadDark} color={colorThead} borderBottom={'none'}>
                                    <VStack spacing={4}>
                                        <Text fontSize='xl' fontWeight='bold'>
                                            Im Pott
                                        </Text>
                                        <Text fontSize='lg'>{pottMoney.toFixed(2)}€</Text>
                                    </VStack>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td bg={bgTheadMiddle} color={colorThead}>
                                    <Flex>
                                        <Text mr={2}>ST-Sieger (25%)</Text>
                                        <BiSolidCircleQuarter />
                                    </Flex>
                                </Td>
                                {gamedays.slice(round, round + 17).map(({ id, winners, money }) => (
                                    <Td key={id} bg={bgTheadLight} color={colorThead}>
                                        <Box textAlign={'center'} mb={1}>
                                            {winners} x
                                        </Box>
                                        <Box textAlign={'center'}>{money!.gameday.toFixed(2)}€</Box>
                                    </Td>
                                ))}
                            </Tr>
                            <Tr>
                                <Td borderBottomLeftRadius={10} bg={bgTheadDark} color={colorThead} borderBottom={'none'}>
                                    <Flex>
                                        <Text mr={2}>Pott (75%)</Text>
                                        <BiSolidCircleThreeQuarter />
                                    </Flex>
                                </Td>
                                {gamedays.slice(round, round + 17).map(({ id, money }) => (
                                    <Td key={id} bg={bgTheadMiddle} color={colorThead} borderBottom={'none'}>
                                        <Box textAlign={'center'}>{money!.pott.toFixed(2)}€</Box>
                                    </Td>
                                ))}
                            </Tr>
                        </Tfoot>
                    </Table>
                </Stack>
            </Flex>
        </Container>
    );
};

interface RotatedSpanProps {
    children: string | string[];
}

const RotatedSpan: React.FunctionComponent<RotatedSpanProps> = ({ children }) => (
    <Center>
        <Box as={'span'} whiteSpace={'nowrap'} transform={'rotate(180deg)'} style={{ writingMode: 'vertical-rl' }}>
            {children}
        </Box>
    </Center>
);

interface IDesktopTooltipProps {
    section: 'body' | 'footer';
    baseMoney: number;
    points: number;
    pointsMoney: number;
    missed: number;
    missedMoney: number;
    isMax?: boolean;
    gamedayMoney?: number;
    sumMoney: number;
}

const DesktopTooltip: React.FC<IDesktopTooltipProps> = (props) => {
    const { baseMoney, section, points, pointsMoney, missed, missedMoney, isMax, gamedayMoney, sumMoney } = props;
    const color1 = section === 'body' ? 'red.500' : 'green.500';
    const color2 = section === 'footer' ? 'red.500' : 'green.500';

    return (
        <Grid p={2} templateColumns={'repeat(3, 1fr)'}>
            <GridItem colSpan={2}>Einsatz</GridItem>
            <GridItem fontFamily={'monospace'} color={color1} textAlign={'right'}>
                {baseMoney.toFixed(2)}€
            </GridItem>
            <GridItem>Punkte</GridItem>
            <GridItem fontFamily={'monospace'} textAlign={'center'}>
                {points}
            </GridItem>
            <GridItem fontFamily={'monospace'} color={points !== 0 ? color2 : ''} textAlign={'right'}>
                {pointsMoney.toFixed(2)}€
            </GridItem>
            <GridItem>Vergessen</GridItem>
            <GridItem fontFamily={'monospace'} textAlign={'center'}>
                {missed}
            </GridItem>
            <GridItem fontFamily={'monospace'} color={missed !== 0 ? color1 : ''} textAlign={'right'}>
                {missedMoney.toFixed(2)}€
            </GridItem>
            {gamedayMoney && (
                <>
                    <GridItem>Spieltagsgewinn</GridItem>
                    <GridItem textAlign={'center'}>
                        {isMax ? <CheckIcon fontSize={'0.75rem'} color={'green.500'} /> : <CloseIcon fontSize={'0.75rem'} color={'red.500'} />}
                    </GridItem>
                    <GridItem fontFamily={'monospace'} textAlign={'right'}>
                        {isMax ? <Text color={isMax ? 'green.500' : ''}>{gamedayMoney.toFixed(2)}€</Text> : '0.00€'}
                    </GridItem>
                </>
            )}
            <GridItem colSpan={2} mt={2}>
                Summe
            </GridItem>
            <GridItem fontFamily={'monospace'} textAlign={'right'} borderTop='3px double white' mt={2}>
                <Text color={sumMoney > 0 ? 'green.500' : sumMoney < 0 ? 'red.500' : ''}>{sumMoney.toFixed(2)}€</Text>
            </GridItem>
        </Grid>
    );
};

const MobileView: React.FC<IViewProps> = (props) => {
    return <div>Mobile</div>;
};

export default GraftippGamedays;
