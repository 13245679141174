import React from 'react';
import { NavLink } from 'react-router-dom';
import { ICustomNavLinkProps } from './navLinks';

export const DesktopNavLink: React.FC<ICustomNavLinkProps> = (props) => (
    <NavLink
        to={props.to}
        style={({ isActive }) => {
            return {
                fontSize: '18px',
                fontWeight: isActive ? 700 : 300,
            };
        }}
    >
        {props.label}
    </NavLink>
);
