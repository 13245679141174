import { Box, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';

const Home: React.FC = () => (
    <VStack justifyContent={'center'} alignItems={'center'}>
        <Heading>Herzlich Willkommen</Heading>
        <Box w={600} h={600} bg='primary.500' borderRadius='50%' mt={10}>
            <Flex justifyContent={'center'} alignItems={'center'} h={450}>
                <Text fontSize={'375px'} fontWeight={300} color='white'>
                    g
                </Text>
                <Text fontSize={'375px'} fontWeight={700} color='gray.700'>
                    t
                </Text>
            </Flex>
        </Box>
    </VStack>
);

export default Home;
