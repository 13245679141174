import { Heading, Select, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { NavLinkButton } from 'components/controls';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { useGetCurrentGamedayQuery, useGetGoalgettersQuery, useGetMatchesQuery, useGetTableQuery } from './openligaApi';
import OpenligaTable from './OpenligaTable';
import OpenligaSeasonTabs from './OpenligaSeasonTabs';
import OpenligaResults from './OpenligaResults';
import OpenligaStatistics from './OpenligaStatistics';
import { history } from 'helpers/history';
import { filterActions } from 'features/filter/filterSlice';

const OpenligaLayout: React.FC = () => {
    const {
        openliga: { season: seasonOpenliga, section: sectionOpenliga },
    } = useAppSelector((state) => state.filter);

    const dispatch = useAppDispatch();

    const { data: currentGameday, isLoading: isLoadingCGD, error: errorCGD } = useGetCurrentGamedayQuery({ leagueShortcut: 'bl1' });
    const { data: goalgetters, isLoading: isLoadingGG, error: errorGG } = useGetGoalgettersQuery({ leagueShortcut: 'bl1', leagueSeason: seasonOpenliga });
    const { data: matches, isLoading: isLoadingM, error: errorM } = useGetMatchesQuery({ leagueShortcut: 'bl1', leagueSeason: seasonOpenliga });
    const { data: table, isLoading: isLoadingTbl, error: errorTbl } = useGetTableQuery({ leagueShortcut: 'bl1', leagueSeason: seasonOpenliga });

    const error = errorCGD || errorGG || errorM || errorTbl;
    const isLoading = isLoadingCGD || isLoadingGG || isLoadingM || isLoadingTbl;
    const isIndex = useMatch('/buli');

    const heading = useBreakpointValue({
        base: 'Saison',
        md: 'Die 1. Fußball Bundesliga in der Saison',
    });

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const openligaSection = event.target.value as 'tabelle' | 'ergebnisse' | 'statistiken';

        const path = `/buli/${openligaSection}/${seasonOpenliga}`;
        if (history.navigate) {
            history.navigate(path);
        }

        dispatch(filterActions.changeOpenligaSection(openligaSection));
    };

    useEffect(() => {
        if (isIndex && history.navigate) {
            history.navigate(`/buli/${sectionOpenliga}/${seasonOpenliga}`);
        }
    }, [isIndex, seasonOpenliga, sectionOpenliga]);

    return (
        <Stack direction={'column'} w={'100%'} spacing={4} alignItems={'flex-start'}>
            <Heading size={{ base: 'md', md: 'lg', lg: '2xl' }}>
                {heading} {seasonOpenliga}/{(seasonOpenliga % 100) + 1}
            </Heading>
            <Stack direction={'row'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                <NavLinkButton to={`tabelle/${seasonOpenliga}`} minW={150}>
                    Tabelle
                </NavLinkButton>
                <NavLinkButton to={`ergebnisse/${seasonOpenliga}`} minW={150}>
                    Ergebnisse
                </NavLinkButton>
                <NavLinkButton to={`statistiken/${seasonOpenliga}`} minW={150}>
                    Statistiken
                </NavLinkButton>
            </Stack>
            <Select placeholder='Bereich wählen' onChange={handleSelectChange} display={{ base: '', md: 'none' }}>
                <option value='tabelle'>Tabelle</option>
                <option value='ergebnisse'>Ergebnisse</option>
            </Select>
            <Stack direction={'column'} w={'100%'} spacing={4}>
                {!isIndex && <OpenligaSeasonTabs season={seasonOpenliga} />}
                <Routes>
                    <Route index element={<Text>Wähle die Tabelle, die Ergebnisse oder die Statistiken</Text>} />
                    <Route path='tabelle/:season' element={<OpenligaTable isLoading={isLoadingTbl} error={errorTbl} data={{ table }} />} />
                    <Route
                        path='ergebnisse/:season'
                        element={<OpenligaResults isLoading={isLoading} error={error} data={{ currentGameday, goalgetters, matches, table }} />}
                    />
                    <Route path='statistiken/:season' element={<OpenligaStatistics isLoading={isLoadingM} error={errorM} data={{ matches }} />} />
                    <Route path='*' element={<Navigate to='/buli' />} />
                </Routes>
            </Stack>
        </Stack>
    );
};

export default OpenligaLayout;
