import { Box, Container } from '@chakra-ui/react';
import { Nav } from '../nav';

interface ILayoutProps {
    children: React.ReactElement;
}

const Layout: React.FC<ILayoutProps> = (props) => {
    return (
        <Box id='app'>
            {/* <Alert /> */}
            <Nav />
            <Container id='content' maxW={'container.2xl'} p={6}>
                {props.children}
            </Container>
        </Box>
    );
};

export default Layout;
