import { Box, useColorModeValue } from '@chakra-ui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React from 'react';

interface IErrorBoxProps {
    error: FetchBaseQueryError | SerializedError | string;
}

const ErrorBox: React.FC<IErrorBoxProps> = (props) => (
    <Box p={2} bg={useColorModeValue('danger.500', 'danger.200')} color={useColorModeValue('white', 'gray.800')}>
        {typeof props.error === 'string' ? props.error : <pre>{JSON.stringify(props.error, null, 2)}</pre>}
    </Box>
);

export default ErrorBox;
