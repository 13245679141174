import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// reducers
import authSlice from 'features/auth/authSlice';
// import dataSlice from 'features/data/dataSlice';
import filterSlice from 'features/filter/filterSlice';

// apis
import graftippApi from 'features/graftipp/graftippApi';
import openligaApi from 'features/openliga/openligaApi';

export const store = configureStore({
    reducer: {
        authentication: authSlice,
        filter: filterSlice,
        [graftippApi.reducerPath]: graftippApi.reducer,
        [openligaApi.reducerPath]: openligaApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(graftippApi.middleware, openligaApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
