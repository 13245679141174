import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterState } from './types';
import { graftippSeason, openligaSeason } from 'common/constants';

// create slice
const name = 'filter';
const initialState: FilterState = createInitialState();
const reducers = createReducers();
const filterSlice = createSlice({ name, initialState, reducers });

// exports
export const filterActions = { ...filterSlice.actions };
export default filterSlice.reducer;

// implementation
function createInitialState(): FilterState {
    return {
        graftipp: {
            round: 0,
            season: graftippSeason,
            section: 'gesamt',
        },
        openliga: {
            season: openligaSeason,
            section: 'tabelle',
        },
        payments: {
            grouped: false,
        },
    };
}

function createReducers() {
    return {
        changeGraftippRound,
        changeGraftippSeason,
        changeGraftippSection,
        changeGrouped,
        changeOpenligaSeason,
        changeOpenligaSection,
    };

    function changeGraftippRound(state: FilterState, action: PayloadAction<0 | 17>) {
        state.graftipp.round = action.payload;
    }

    function changeGraftippSeason(state: FilterState, action: PayloadAction<number>) {
        state.graftipp.season = action.payload;
    }

    function changeGraftippSection(state: FilterState, action: PayloadAction<'gesamt' | 'spieltage' | 'statistiken'>) {
        state.graftipp.section = action.payload;
    }

    function changeGrouped(state: FilterState, action: PayloadAction<boolean>) {
        state.payments.grouped = action.payload;
    }

    function changeOpenligaSeason(state: FilterState, action: PayloadAction<number>) {
        state.openliga.season = action.payload;
    }

    function changeOpenligaSection(state: FilterState, action: PayloadAction<'tabelle' | 'ergebnisse' | 'statistiken'>) {
        state.openliga.section = action.payload;
    }
}
