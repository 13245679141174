import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import { Gameday, GoalGetter, Match, TableTeam } from './types';
import { Box, Flex, Progress, Show, Stack, Table, Tbody, Td, Text, Tr, useColorModeValue } from '@chakra-ui/react';
import { groupByToMap } from 'functions';
import OpenligaResultsTable from './OpenligaResultsTable';

interface IOpenligaResultsProps {
    error: FetchBaseQueryError | SerializedError | undefined;
    isLoading: boolean;
    data: {
        table: TableTeam[] | undefined;
        goalgetters: GoalGetter[] | undefined;
        matches: Match[] | undefined;
        currentGameday: Gameday | undefined;
    };
}

const OpenligaResults: React.FC<IOpenligaResultsProps> = (props) => {
    const { error, isLoading, data } = props;
    const { currentGameday, goalgetters, matches, table } = data;

    const bg = useColorModeValue('white', 'gray.900');

    const matchesForMobile = React.useMemo(() => {
        if (matches) {
            let temp = [...matches];
            temp = temp.sort((a, b) => a.matchDateTime.localeCompare(b.matchDateTime));
            return groupByToMap(temp, (match) => match.group.groupName);
        }
        return new Map<string, Match[]>();
    }, [matches]);

    return (
        <>
            <Show above='xl'>
                <Flex w={'100%'} boxShadow={'md'} p={2} bg={bg}>
                    {isLoading ? (
                        <Progress isIndeterminate />
                    ) : error ? (
                        <Text>Datenabfrage aktuell nicht möglich</Text>
                    ) : (
                        <OpenligaResultsTable currentGameday={currentGameday!} goalgetters={goalgetters!} matches={matches!} table={table!} bg={bg} />
                    )}
                </Flex>
            </Show>
            <Show below='xl'>
                <Stack direction={'column'} spacing={4} p={2}>
                    {Array.from(matchesForMobile.entries()).map((entry) => {
                        const [gameday, matches] = entry;
                        return <MobileGameday key={gameday} gameday={gameday} matches={matches} bg={bg} />;
                    })}
                </Stack>
            </Show>
        </>
    );
};

interface IMobileGamedayProps {
    gameday: string;
    matches: Match[];
    bg: 'white' | 'gray.900';
}

const MobileGameday: React.FunctionComponent<IMobileGamedayProps> = ({ gameday, matches, bg }) => (
    <Box w={'100%'} p={2} boxShadow={'md'} bg={bg}>
        <Text as={'b'} textAlign={'center'}>
            {gameday}
        </Text>
        <Table size={'sm'}>
            <Tbody>
                {matches.map(({ matchID, matchResults, team1, team2 }) => {
                    let mr = matchResults.find((x) => x.resultName === 'Endergebnis');
                    return (
                        <Tr key={matchID}>
                            <Td textAlign={'right'}>{team1.shortName}</Td>
                            <Td textAlign={'center'}>
                                {mr?.pointsTeam1} : {mr?.pointsTeam2}
                            </Td>
                            <Td>{team2.shortName}</Td>
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    </Box>
);

export default OpenligaResults;
