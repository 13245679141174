import { StyleFunctionProps, defineStyle, defineStyleConfig, extendTheme, theme, withDefaultColorScheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const graftipp = defineStyle(() => {
    return {
        p: 0,
        boxShadow: 'md',
        _light: {
            bg: 'white',
        },
        _dark: {
            bg: 'gray.900',
        },
    };
});

const containerTheme = defineStyleConfig({
    variants: { graftipp },
});

export const customTheme = extendTheme(
    {
        colors: {
            primary: theme.colors.green,
            secondary: theme.colors.orange,
            danger: theme.colors.red,
        },
        styles: {
            global: (props: StyleFunctionProps) => ({
                'html, body': {
                    color: mode('gray.700', 'white')(props),
                    bg: mode('#f4f5fd', 'gray.800')(props),
                },
            }),
        },
        components: {
            Container: containerTheme,
        },
    },
    withDefaultColorScheme({ colorScheme: 'primary' }),
);
