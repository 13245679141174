import React from 'react';
import { MatchExtended } from './types';
import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Tooltip,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import OpenligaMatchFact from './OpenligaMatchFact';

interface IOpenligaMatchCellProps {
    match: MatchExtended | null;
}

const OpenligaMatchCell: React.FC<IOpenligaMatchCellProps> = (props) => {
    const { match } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    const bgMatchIsNext = useColorModeValue('secondary.200', 'secondary.700');
    const bgMatchIsFinished = useColorModeValue('primary.200', 'primary.700');
    const bgMatchIsOpen = useColorModeValue('gray.200', 'gray.700');
    const bgModal = useColorModeValue('primary.500', 'primary.200');
    const colorModal = useColorModeValue('white', 'black');

    if (!match) {
        return <Box w={'100%'} h={'100%'} />;
    }

    const { matchIsFinished, matchIsNext, matchResults, matchDateTime, group } = match;
    const result = matchResults.find((mr) => mr.resultName === 'Endergebnis');
    const date = new Date(matchDateTime).toLocaleDateString('de-DE', { weekday: 'long', day: '2-digit', month: '2-digit', year: '2-digit' });

    return (
        <>
            <Tooltip label={group.groupName}>
                <Flex
                    w={'100%'}
                    h={'100%'}
                    alignItems={'center'}
                    textAlign={'center'}
                    justifyContent={'center'}
                    borderRadius={8}
                    onClick={onOpen}
                    bg={matchIsNext ? bgMatchIsNext : matchIsFinished ? bgMatchIsFinished : bgMatchIsOpen}
                    fontSize={!matchIsFinished ? 'sm' : 'xl'}
                >
                    {matchIsFinished ? `${result?.pointsTeam1} : ${result?.pointsTeam2}` : date}
                </Flex>
            </Tooltip>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW={matchIsFinished ? 'container.lg' : 'container.sm'}>
                    <ModalHeader bg={bgModal} color={colorModal} borderTopRadius={'md'}>
                        Spielinfos
                    </ModalHeader>
                    <ModalBody p={4}>
                        <OpenligaMatchFact match={match} />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Schließen</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default OpenligaMatchCell;
