import { ArrowUpDownIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Collapse,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    IconButton,
    Progress,
    Select,
    Stack,
    Stat,
    StatLabel,
    StatNumber,
    Switch,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { useGetPaymentsQuery } from './graftippApi';
import { ApiPayment } from './types';
import { ApiUserBasic } from 'common/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { filterActions } from 'features/filter/filterSlice';
import { groupByToMap } from 'functions';
import { ColorType } from 'common/types';
import { ErrorBox } from 'components/controls';

const GraftippPayments: React.FC = () => {
    const { data, isLoading, error } = useGetPaymentsQuery();

    return <>{error ? <ErrorBox error={error} /> : isLoading ? <Progress isIndeterminate /> : data ? <View payments={data} /> : <div>FATAL</div>}</>;
};

const col1: ColorType = { light: 'primary.500', dark: 'primary.200' };
const col2: ColorType = { light: 'white', dark: 'gray.700' };
const col3: ColorType = { light: 'primary.100', dark: 'primary.900' };

interface IViewProps {
    payments: ApiPayment[];
}

const View: React.FC<IViewProps> = (props) => {
    const { payments } = props;
    let users: ApiUserBasic[] = payments.map((p) => p.user);
    users = [...new Map(users.map((u) => [u.id, u])).values()];

    const [user, setUser] = useState<ApiUserBasic>();
    const [userPayments, setUserPayments] = useState<ApiPayment[]>([]);

    const dispatch = useAppDispatch();
    const {
        payments: { grouped },
    } = useAppSelector((state) => state.filter);

    const bgTableHead = useColorModeValue(col1.light, col1.dark);
    const colorTableHead = useColorModeValue(col2.light, col2.dark);
    const kontoHeading = useBreakpointValue({ base: 'Konto', md: 'Kontostand' });

    useEffect(() => {
        if (user) {
            const temp = payments.filter((x) => x.user.id === user.id);
            setUserPayments(temp);
        } else {
            setUserPayments([]);
        }
    }, [payments, user]);

    const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const userId = event.target.value;
        const user = users.find((user) => user.id === userId);
        setUser(user);
    };

    const switchGrouped = () => {
        dispatch(filterActions.changeGrouped(!grouped));
    };

    const total = userPayments.reduce((p, c) => p + c.amount, 0) ?? 0;
    const firstPayment = userPayments.filter((p) => p.paymentType.isFirst).reduce((p, c) => p + c.amount, 0) ?? 0;
    const paymentsTemp = userPayments.filter((p) => !p.paymentType.isFirst) ?? [];
    const paymentsBySeason = groupByToMap(paymentsTemp, (payment) => payment.season.description);

    return (
        <Container maxW={'container.sm'} variant={'graftipp'} p={4}>
            <Stack direction={'column'} spacing={4}>
                <Select value={user?.id} placeholder='Auswählen...' onChange={handleUserChange}>
                    {users.map((user) => (
                        <option key={user.id} value={user.id}>
                            {user.fullName}
                        </option>
                    ))}
                </Select>
                {user && (
                    <>
                        <Stack direction={'row'}>
                            <Stat>
                                <StatLabel>Aktueller Kontostand</StatLabel>
                                <StatNumber color={total < 0 ? 'red.500' : ''}>{total.toFixed(2)}€</StatNumber>
                            </Stat>
                            <FormControl display={{ base: 'none', md: 'contents' }}>
                                <FormLabel display={'contents'} htmlFor='grouped'>
                                    Details?
                                </FormLabel>
                                <Switch ml={2} isChecked={grouped} onChange={switchGrouped} />
                            </FormControl>
                        </Stack>
                        <Table variant={'simple'} size={'sm'}>
                            <colgroup>
                                <col width='10%' />
                                <col width='65%' />
                                <col width='25%' />
                            </colgroup>
                            <Thead bg={bgTableHead}>
                                <Tr>
                                    <Th />
                                    <Th color={colorTableHead} py={2}>
                                        Saison
                                    </Th>
                                    <Th color={colorTableHead} py={2} isNumeric>
                                        {kontoHeading}
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>
                                        <IconButton
                                            aria-label='header row'
                                            size={['xs', 'xs', 'sm']}
                                            paddingInlineStart='0px!important'
                                            paddingInlineEnd='0px!important'
                                            variant={'outline'}
                                            colorScheme='primary'
                                            icon={<ArrowUpDownIcon />}
                                        />
                                    </Td>
                                    <Td fontWeight={'bold'}>Anfangskontostand</Td>
                                    <Td fontWeight={'bold'} color={firstPayment < 0 ? 'red.500' : ''} isNumeric>
                                        {firstPayment.toFixed(2)}€
                                    </Td>
                                </Tr>
                                {Array.from(paymentsBySeason, ([season, userPayments]) => (
                                    <RowHelper key={season} season={season} payments={userPayments} details={grouped} />
                                ))}
                            </Tbody>
                        </Table>
                    </>
                )}
            </Stack>
        </Container>
    );
};

interface IRowHelperProps {
    season: string;
    payments: ApiPayment[];
    details: boolean;
}

const RowHelper: React.FC<IRowHelperProps> = (props) => {
    const { season, payments, details } = props;
    const { isOpen, onToggle } = useDisclosure();

    const bgTableHead = useColorModeValue(col1.light, col1.dark);
    const colorTableHead = useColorModeValue(col2.light, col2.dark);
    const rowBg = useColorModeValue(col3.light, col3.dark);

    const sum = payments.reduce((p, c) => p + c.amount, 0);
    const groupedByFinance = groupByToMap(payments, (payment) => payment.paymentType.title);

    return (
        <>
            <Tr bg={isOpen ? rowBg : ''}>
                <Td borderBottom={'unset'}>
                    <IconButton
                        aria-label='expand'
                        size={['xs', 'xs', 'sm']}
                        paddingInlineStart='0px!important'
                        paddingInlineEnd='0px!important'
                        colorScheme='primary'
                        icon={isOpen ? <TriangleDownIcon /> : <TriangleUpIcon />}
                        onClick={onToggle}
                    />
                </Td>
                <Td borderBottom={'unset'}>{season}</Td>
                <Td borderBottom={'unset'} color={sum < 0 ? 'red.500' : ''} isNumeric>
                    {sum.toFixed(2)}€
                </Td>
            </Tr>
            <Tr bg={isOpen ? rowBg : ''}>
                <Td colSpan={3} py={0}>
                    <Collapse in={isOpen} animateOpacity>
                        <Box py={2}>
                            <Heading size={'sm'} color={bgTableHead} mb={2}>
                                Übersicht
                            </Heading>
                            <Table size={'sm'}>
                                <Thead bg={bgTableHead}>
                                    <Tr>
                                        {details ? <Th color={colorTableHead}>Datum</Th> : null}
                                        <Th color={colorTableHead}>Art</Th>
                                        <Th color={colorTableHead} isNumeric>
                                            Betrag
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {details
                                        ? payments.map(({ id, booked, amount, paymentType }) => {
                                              const bookedStr = new Date(booked).toLocaleDateString('de-DE', {
                                                  day: '2-digit',
                                                  month: '2-digit',
                                                  year: 'numeric',
                                              });
                                              return (
                                                  <Tr key={id}>
                                                      <Td>{bookedStr}</Td>
                                                      <Td display={'table-cell'}>
                                                          <Flex>
                                                              {amount > 0 ? <GreenPlusIcon /> : <RedMinusIcon />}
                                                              <Box ml={2}>
                                                                  {paymentType.title} ({paymentType.description})
                                                              </Box>
                                                          </Flex>
                                                      </Td>
                                                      <Td color={amount < 0 ? 'red.500' : ''} isNumeric>
                                                          {amount.toFixed(2)}€
                                                      </Td>
                                                  </Tr>
                                              );
                                          })
                                        : Array.from(groupedByFinance, ([kind, payments]) => {
                                              const sum = payments.reduce((p, c) => p + c.amount, 0);
                                              return (
                                                  <Tr key={kind}>
                                                      <Td>{kind}</Td>
                                                      <Td color={sum < 0 ? 'red.500' : ''} isNumeric>
                                                          {sum.toFixed(2)}€
                                                      </Td>
                                                  </Tr>
                                              );
                                          })}
                                </Tbody>
                            </Table>
                        </Box>
                    </Collapse>
                </Td>
            </Tr>
        </>
    );
};

const GreenPlusIcon: React.FC = () => (
    <IconContext.Provider value={{ color: 'var(--chakra-colors-green-600)' }}>
        <MdAddCircleOutline />
    </IconContext.Provider>
);

const RedMinusIcon: React.FC = () => (
    <IconContext.Provider value={{ color: 'var(--chakra-colors-red-600)' }}>
        <MdRemoveCircleOutline />
    </IconContext.Provider>
);

export default GraftippPayments;
