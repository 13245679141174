import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { Box, Collapse, Flex, HStack, IconButton, Stack, Text, chakra, useColorMode, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { useAppSelector } from 'app/hooks';
import { Role } from 'common/enums';
import React from 'react';
import { Link } from 'react-router-dom';
import { DesktopNavLink } from './CustomNavLink';
import { MenuToggle } from './MenuToggle';
import { adminLinks, publicLinks } from './navLinks';

const logoSpacing = 6;

const Nav: React.FC = () => {
    const { isOpen, onToggle } = useDisclosure();
    const { toggleColorMode } = useColorMode();

    const { user } = useAppSelector((state) => state.authentication);
    const isAdmin = (user && user.role === Role.Admin) || false;

    const ColorModeIcon = useColorModeValue(SunIcon, MoonIcon);

    return (
        <NavContainer>
            <Flex h={{ base: 12, md: 16 }} alignItems={'center'} justifyContent={'space-between'}>
                <MenuToggle size={'sm'} aria-label={'Open Menu'} display={{ base: 'flex', md: 'none' }} isOpen={isOpen} onToggle={onToggle} />
                <HStack spacing={logoSpacing} alignItems={'center'} flexGrow={{ md: 1 }}>
                    <Logo />
                    <HStack spacing={4} display={{ base: 'none', md: 'flex' }}>
                        {publicLinks.map((navLink, index) => (
                            <DesktopNavLink key={index} {...navLink} />
                        ))}
                        {isAdmin && adminLinks.map((adminLink, index) => <DesktopNavLink key={index} {...adminLink} />)}
                    </HStack>
                </HStack>
                <IconButton size={{ base: 'sm', md: 'md' }} aria-label='Color Mode' icon={<ColorModeIcon />} onClick={toggleColorMode} />
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav isAdmin={isAdmin} />
            </Collapse>
        </NavContainer>
    );
};

interface INavContainerProps {
    children: React.ReactElement[];
}

const NavContainer: React.FC<INavContainerProps> = (props) => {
    const navRef = React.useRef<HTMLDivElement | null>(null);
    const dimensions = useSize(navRef);

    return (
        <Box id='navContainer'>
            <chakra.nav ref={navRef} bg={useColorModeValue('white', 'gray.700')} px={4} boxShadow={'md'} position={'fixed'} w={'100%'} zIndex={3}>
                {props.children}
            </chakra.nav>
            <Box h={`${dimensions?.height}px`} />
        </Box>
    );
};

interface IMobileNavProps {
    isAdmin: boolean;
}

const MobileNav = React.forwardRef<HTMLDivElement, IMobileNavProps>((props, ref) => {
    const { isAdmin } = props;
    return (
        <Stack ref={ref} spacing={4} p={4}>
            {publicLinks.map((link, index) => (
                <Link key={index} to={link.to}>
                    {link.label}
                </Link>
            ))}
            {isAdmin &&
                adminLinks.map((link, index) => (
                    <Link key={index} to={link.to}>
                        {link.label}
                    </Link>
                ))}
        </Stack>
    );
});

const Logo: React.FC = () => (
    <Link to='/'>
        <Flex
            pr={{ base: 0, md: logoSpacing }}
            borderRight={{ base: 'none', md: '3px solid' }}
            borderRightColor={useColorModeValue('primary.500!important', 'primary.200!important')}
        >
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={300} color={useColorModeValue('primary.500', 'primary.200')}>
                graf
            </Text>
            <Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight={700}>
                tipp
            </Text>
        </Flex>
    </Link>
);

export default Nav;
