import { Gameday, Goal, GoalGetter, Match, MatchExtended } from 'features/openliga/types';

export function getMatch(currentGameday: Gameday, goalgetters: GoalGetter[], matches: Match[], idHome: number, idGuest: number): MatchExtended | null {
    const match = matches.find((m) => m.team1.teamId === idHome && m.team2.teamId === idGuest);
    if (!match) {
        return null;
    }

    const matchIsNext = currentGameday ? match.group.groupID === currentGameday.groupID : false;
    const goals: Goal[] =
        match.goals?.map((goal) => {
            const goalgetter = goalgetters.find((g) => g.goalGetterId === goal.goalGetterID);
            return { ...goal, goalGetterName: goalgetter?.goalGetterName ?? '' };
        }) ?? [];

    return { ...match, matchIsNext, goals };
}
