import { Select, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useAppDispatch } from 'app/hooks';
import { filterActions } from 'features/filter/filterSlice';
import { range } from 'functions';
import { history } from 'helpers/history';
import React from 'react';

const currentSeason = Number(process.env.REACT_APP_OPENLIGA_SEASON);

interface IOpenligaSeasonTabsProps {
    season: number;
}

const OpenligaSeasonTabs: React.FC<IOpenligaSeasonTabsProps> = (props) => {
    const { season } = props;
    const dispatch = useAppDispatch();

    const handleTabsChange = (index: number) => {
        const tempSeason = currentSeason - index;
        const path = history.location?.pathname.split('/').slice(0, -1).join('/') + `/${tempSeason}`;
        if (path && history.navigate) {
            history.navigate(path);
        }
        dispatch(filterActions.changeOpenligaSeason(tempSeason));
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const tempSeason = Number(event.target.value);
        const path = history.location?.pathname.split('/').slice(0, -1).join('/') + `/${tempSeason}`;
        if (path && history.navigate) {
            history.navigate(path);
        }
        dispatch(filterActions.changeOpenligaSeason(tempSeason));
    };

    return (
        <>
            <Tabs index={currentSeason - season} onChange={handleTabsChange} display={{ base: 'none', md: 'block' }}>
                <TabList>
                    {range(2011, currentSeason).map((season) => (
                        <Tab key={season}>{season}</Tab>
                    ))}
                </TabList>
            </Tabs>
            <Select value={season} onChange={handleSelectChange} display={{ base: 'block', md: 'none' }}>
                {range(2011, currentSeason).map((season) => (
                    <option key={season} value={season}>
                        {season}
                    </option>
                ))}
            </Select>
        </>
    );
};

export default OpenligaSeasonTabs;
